<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">
        <tchtree v-model="searchForm.office_id"></tchtree>
        <el-select v-model="searchForm.sxqx" clearable placeholder="实习去向" size="small"
                   style="width:130px;">
          <el-option :label="item.param_desc" v-for="item in sxqxArr" :key="item.param_value" :value="item.param_value"></el-option>
        </el-select>
        <el-select v-model="searchForm.enter_id" clearable filterable placeholder="选择医院" size="small"
                   style="margin-left: 10px;">
          <el-option :label="item.firm_name" v-for="item in enters" :key="item.id" :value="item.id"></el-option>
        </el-select>
        <el-input placeholder="姓名/学号" v-model="searchForm.keyword" size="small" class="input-with-select"
          style="width:200px;margin-left: 10px;" clearable>
        </el-input>
        <el-button icon="el-icon-search" style="margin-left:5px" size="small" type="primary"
                   @click="() => { page.current_page = 1; getList() }">搜索
        </el-button>
        <el-button icon="el-icon-download" style="margin-left:5px" size="small" type="primary" plain
                   @click="exportToExcel">导出实习记录
        </el-button>
      </div>
    </el-row>
    <div class="dividerBar"></div>

    <el-table v-tableFit
              ref="multipleTable"
              @selection-change="handleSelectionChange"
              :data="tableData"
              row-key="id"
              height="100%" size="small" stripe border
              style="width:fit-content;width:100%;">
      <el-table-column type="index" label="序号" width="55px" align="center"/>
      <el-table-column label="学号" prop="stu_code" show-overflow-tooltip width="150px"/>
      <el-table-column label="姓名" prop="stu_name" width="100px"/>
      <el-table-column label="实习去向" prop="param_desc" width="100px"/>
      <el-table-column label="班级" prop="class_name" width="200px"/>
      <el-table-column label="医院名称" prop="firm_name" show-overflow-tooltip min-width="200px"/>
      <el-table-column label="岗位" prop="job" width="200px"/>
      <el-table-column label="单位联系人" prop="firm_content_name" width="200px"/>
      <el-table-column label="单位联系人电话" prop="firm_content_tel" width="200px"/>
      <el-table-column label="分配时间" prop="cdate" width="200px"/>
      <el-table-column label="实习开始时间" prop="start_time" width="120px"/>
      <el-table-column label="实习结束时间" prop="end_time" width="120px"/>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-tag class="pointer" @click="handleModify(scope.row)" type="primary" size="mini">查看</el-tag>
          <el-tag class="pointer ml10" @click="handleModify(scope.row, 'edit')" type="success" size="mini">修改</el-tag>
          <el-tag class="pointer" @click="handleDel(scope.row)" type="danger"
                  size="mini" style="margin-left:10px;">删除</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
                   :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
                   :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>

    <el-dialog :visible.sync="showAllocateDialog" :close-on-click-modal="false"
               custom-class="cus_dialog">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">实习分配</span>
      </div>
      <el-form :model="formData" :rules="rules" ref="dynamicValidateForm" label-width="120px">
        <el-form-item label="实习起止时间"
                      :rules="rules.start_end_date"
                      prop="start_end_date">
          <el-date-picker
              v-model="formData.start_end_date"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="实习医院"
                      :rules="rules.enter_id"
                      prop="enter_id">
          <el-select v-model="formData.enter_id" placeholder="请选择实习医院" filterable>
            <el-option
                v-for="item in enters"
                :key="item.id"
                :label="item.firm_name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="实习岗位" prop="job">
          <el-input v-model="formData.job" placeholder="请输入实习岗位" style="width: 200px;"></el-input>
        </el-form-item>
        <el-form-item label="职位" prop="stu_position">
          <el-select v-model="formData.stu_position" placeholder="请选择职位">
            <el-option label="无职位" :value="0"></el-option>
            <el-option label="小组长" :value="1"></el-option>
            <el-option label="大组长" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showAllocateDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">提 交</el-button>
      </div>
    </el-dialog>
    <el-dialog ref="ruleForm" :visible.sync="dialogForm" :close-on-click-modal="false"
               custom-class="cus_dialog" width="1000px">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">实习详情</span>
      </div>
      <div class="dialog_right">
        <div v-if="formData.check_reason" class="flexStart"
             style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart" style="min-height: 40px;height: unset;">审批原因</div>
            <div class="cell flexCloumn flex_1"
                 style="height: unset;justify-content: center;min-height: 40px;color:orangered">
              {{ formData.check_reason }}
            </div>
          </div>
        </div>
        <div v-if="formData.revoke_reason" class="flexStart"
             style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart" style="min-height: 40px;height: unset;">撤回原因</div>
            <div class="cell flexCloumn flex_1"
                 style="height: unset;justify-content: center;min-height: 40px;color:orangered">
              {{ formData.revoke_reason }}
            </div>
          </div>
        </div>
        <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
          <span></span>
          <span style="font-weight: 400">学生信息</span>
        </div>

        <div class="flexStart"
             style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
          <div class="lineRow flexStretch" style="width:33.3%;">
            <div class="cell flexStart">姓名</div>
            <div class="cell flexStart flex_1">{{ formData.stu_name }}</div>
          </div>
          <div class="lineRow flexStretch" style="width:33.3%;">
            <div class="cell flexStart">班级</div>
            <div class="cell flexStart flex_1">{{ formData.class_name }}</div>
          </div>
          <div class="lineRow flexStretch" style="width:33.3%;">
            <div class="cell flexStart">实习去向</div>
            <div class="cell flexStart flex_1">{{ sxqxObj[formData.sxqx] }}</div>
          </div>

        </div>
        <div v-if="formData.sxqx == 1 || formData.sxqx == 7" class="cellGroupBarTitle flexStart"
             style="margin-top:10px;">
          <span></span>
          <span style="font-weight: 400">企业信息</span>
        </div>
        <div v-if="formData.sxqx == 1 || formData.sxqx == 7" class="flexStart"
             style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart">企业名称</div>
            <div class="cell flexStart flex_1">{{ formData.firm_name }}</div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">社会统一<br />信用代码</div>
            <div class="cell flexStart flex_1">{{ formData.firm_num }}</div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">企业性质</div>
            <div class="cell flexStart flex_1">{{ qyxzObj[formData.info] }}</div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">企业部门</div>
            <div class="cell flexStart flex_1">{{ formData.department }}</div>
          </div>

          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">实习岗位</div>
            <div class="cell flexStart flex_1">{{ formData.job }}</div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">企业联系人</div>
            <div class="cell flexStart flex_1">{{ formData.firm_content_name }}</div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">联系人电话</div>
            <div class="cell flexStart flex_1">{{ formData.firm_content_tel }}</div>
          </div>

          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">指导老师</div>
            <div class="cell flexStart flex_1">{{ formData.mentor_name }}</div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">指导老师电话</div>
            <div class="cell flexStart flex_1">{{ formData.mentor_tel }}</div>
          </div>


          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">实习薪资</div>
            <div class="cell flexStart flex_1">{{ formData.xinzi }}</div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">薪资范围</div>
            <div class="cell flexStart flex_1">{{ salaryObj[formData.salary] }}</div>
          </div>

          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart">实际上班地址</div>
            <div class="cell flexStart flex_1">{{ formData.address }}</div>
          </div>

          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">安排方式</div>
            <div class="cell flexStart flex_1">{{ anpaiObj[formData.anpai_type] }}</div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">专业对口情况</div>
            <div class="cell flexStart flex_1">{{ formData.is_ok == 1 ? '对口' : (formData.is_ok == 0 ? '不对口' : '-') }}
            </div>
          </div>

          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">有无实习协议</div>
            <div class="cell flexStart flex_1">{{ formData.is_contract == 1 ? '有' : (formData.is_contract == 0 ? '没有'
                : '-') }}</div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">是否购买保险</div>
            <div class="cell flexStart flex_1">{{ formData.insured == 1 ? '有' : (formData.insured == 0 ? '没有' : '-')
              }}</div>
          </div>

          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart">有无公积金</div>
            <div class="cell flexStart flex_1">{{ formData.is_housing_fund == 1 ? '有' : (formData.is_housing_fund == 0
                ? '没有' : '-') }}</div>
          </div>

          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">实习开始时间</div>
            <div class="cell flexStart flex_1">{{ formData.start_time }}</div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">实习结束时间</div>
            <div class="cell flexStart flex_1">{{ formData.end_time }}</div>
          </div>
        </div>
        <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
          <span></span>
          <span style="font-weight: 400">资料附件</span>
        </div>
        <div class="flexStart"
             style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart" style="min-height: 40px;height: unset;">协议/资料</div>
            <div class="cell flexStart flex_1" style="height: unset;min-height: 40px;flex-wrap:wrap;padding-top:8px;">
              <div class="flexCenter" v-for="(f, idx) in formData.cover"
                   style="position:relative;height:100px;width:100px;border:1px dashed #c0ccda;border-radius:6px;margin-right:8px;margin-bottom:8px;">
                <el-image style="max-height:100%;max-width:100%;" :src="f.fileUrl"
                          :preview-src-list="formData.cover.map(e => { return e.fileUrl })" img="cover"
                          :z-index="3000"></el-image>
              </div>
              <div v-if="!formData.cover || formData.cover == 0" class="" style="width:100%;">-</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tchtree from "../com/tchTree.vue";
import moment from "moment";
import {export_json_to_excel} from "../../../public/js/Export2Excel";

export default {
  components: {tchtree},
  data() {
    return {
      activeName: "first",
      isRevoke: false,
      searchForm: {
        keyword: "",
        status: "",
        office_id: "",
        ktype: 'stu_name'
      },
      classArr: [],
      tableData: [],
      dialogForm: false,
      modalTitle: "添加",
      sxqx: ["实习", "参军", "升学/升本", "出国", "长病假", "实习结束"],
      qyxz: [], qyxzObj: {},
      salary: [], salaryObj: {},
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      overlap: null,
      spword: "",
      sxqxArr: [], sxqxObj: {},
      applyList: [],
      dialogExportFormVisible: false,
      dialogChangeVisible: false,
      export_format: "word",
      changeList: [],
      dateRangeArr: [],
      checkBtn: false,
      rules: {
        enter_id: [{required: true, message: '请选择实习医院', trigger: 'change'}],
        start_end_date: [{required: true, message: '请选择实习起止时间', trigger: 'change'}],
      },
      multipleSelection: [],
      batchOptions: [],
      showAllocateDialog: false,
      enters: [],
      formData: {},
      anpaiObj: {
        0: '学校安排', 1: '自主选择', 2: '定向培养'
      },
    };
  },
  mounted() {
    this.getFieldworkBatch()
    this.getEnters()
    this.getComType().then(rr => {
      this.getList();
    });
  },
  methods: {
    getComType() {
      return new Promise(resolve => {
        this.$http
            .post("/api/sys_param_list", {
              param_type: "'ENTER_INFO','SALARY_RANGE','SHIXI_STATUS'",
              used: 0
            })
            .then(res => {
              let qyxz = [];
              let salary = [];
              let sxqxArr = [];
              let qyxzObj = {};
              let salaryObj = {};
              let sxqxObj = {};
              let salaryOption = [];
              for (let item of res.data) {
                if (item.param_type == "ENTER_INFO") {
                  qyxz.push(item);
                  qyxzObj[item.param_value] = item.param_desc
                }
                if (item.param_type == "SALARY_RANGE") {
                  salary.push(item);
                  salaryObj[item.param_value] = item.param_desc
                  let aa = JSON.parse(JSON.stringify(item.param_desc))
                  aa = item.param_desc.split('-')
                  if (aa && aa[0]) { aa[0] = parseInt(aa[0]); }
                  if (aa && aa[1]) { aa[1] = parseInt(aa[1]) }
                  salaryOption.push(aa)
                }
                if (item.param_type == "SHIXI_STATUS") {
                  sxqxArr.push(item);
                  sxqxObj[item.param_value] = item.param_desc
                }
              }
              this.qyxz = qyxz;
              this.salary = salary;
              this.sxqxArr = sxqxArr;
              this.qyxzObj = qyxzObj;
              this.salaryObj = salaryObj;
              this.salaryOption = salaryOption
              this.sxqxObj = sxqxObj;
              resolve();
            });
      });
    },
    getFieldworkBatch() {
      this.$http
          .post("/api/fieldwork_batch_list", {pagesize: 10000})
          .then(res => {
            this.batchOptions = res.data.data
          });
    },
    getEnters() {
      this.$http
          .post("/api/enterprise_list", {pagesize: 10000})
          .then(res => {
            this.enters = res.data.data
          });
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    handleAdd() {
      this.dialogForm = true;
      this.modalTitle = "新增实习批次";
      this.formData = {
        complete_week: 1,
        complete_month: 1,
        dk_count: 1,
        xf_count: 1,
      }
      this.resetForm()
    },
    getList() {
      let data = {};
      data.keyword = this.searchForm.keyword;
      data.sxqx = this.searchForm.sxqx
      data.enter_id = this.searchForm.enter_id
      data.class_ids = this.searchForm.office_id
      data.page = this.page.current_page;
      data.pagesize = this.page.per_page;
      this.$http.post("/api/stu_allocate_list", data).then(res => {
        this.tableData = res.data.data;
        this.page = res.data.page;
      });
    },
    handleDel(row) {
      this.$confirm('此操作不可恢复，确定删除？', {
        type: 'warning'
      }).then(res => {
        this.$http
            .post("/api/stu_enter_soft_delete", {
              id: row.id
            })
            .then(res => {
              this.$message.success("删除成功");
              this.getList();
            });
      })
    },
    handleModify(row, type) {
      let item = {...row}
      item.cover = item.cover ? JSON.parse(item.cover) : "";
      this.sxqxArr.map(d => {
        try {
          if (d.param_value == item.sxqx) {
            item.sxqxLabel = d.param_desc;
          }
        } catch { }
      });
      item.info = item.info >= 0 ? item.info : item.enter_type
      item.info = String(item.info)
      item.infoLabel = this.qyxzObj[item.info]
      item.anpai_type = String(item.anpai_type)
      item.is_ok = String(item.is_ok)
      item.is_contract = String(item.is_contract)
      item.insured = String(item.insured)
      item.is_housing_fund = String(item.is_housing_fund)
      item.start_end_date = [item.start_time, item.end_time]
      this.formData = item
      if (type == 'edit') {
        this.showAllocateDialog = true
      } else {
        this.dialogForm = true
      }
    },
    submitForm() {
      this.$refs.dynamicValidateForm.validate((valid) => {
        if (valid) {
          let formData = {...this.formData}
          formData.start_time = this.formData.start_end_date[0]
          formData.end_time = this.formData.start_end_date[1]
          this.$http.post('/api/stu_internship_allocate', formData).then(res => {
            this.$message.success('提交成功')
            this.showAllocateDialog = false
            this.getList()
          })

        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.dynamicValidateForm.resetFields();
    },
    addItem() {
      this.formData.forms.push({
        start_end_date: [],
        enter_id: "",
        job: "",
        stu_position: 0
      })
    },
    delItem(index) {
      this.formData.forms.splice(index, 1)
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleBatchAllocate() {
      if (!this.searchForm.batch_id) {
        return this.$message.warning('请选择实习批次')
      }
      let msg = "此操作将<strong style='color:#ff0000'>全部学生</strong>分配到指定批次"
      if (this.multipleSelection.length > 0) {
        msg = "此操作将<strong style='color: #ff0000'>所选学生</strong>分配到指定批次"
      }
      this.$confirm(`${msg}，是否继续？`,{
        type: 'warning',
        dangerouslyUseHTMLString: true,
      }).then(()=>{
        let stu_ids = ""
        if (this.multipleSelection.length > 0) {
          stu_ids = this.multipleSelection.map(m => m.id).join(',')
        }
        this.$http.post('/api/allocate_fieldwork_batch', {
          stu_ids: stu_ids,
          batch_id: this.searchForm.batch_id,
          office_id: this.searchForm.office_id,
          keyword: this.searchForm.keyword,
          ktype: this.searchForm.ktype,
        }).then(res => {
          this.$message.success('分配成功')
          this.getList()
        })
      })
    },
    handleStuAllocate() {
      this.showAllocateDialog = true
      this.formData.forms = [
        {
          start_end_date: [],
          enter_id: "",
          job: "",
          stu_position: 0
        }
      ]
      if (this.$refs.dynamicValidateForm) {
        this.resetForm()
      }
    },
    exportToExcel() {
      let data = {};
      data.keyword = this.searchForm.keyword;
      data.sxqx = this.searchForm.sxqx
      data.enter_id = this.searchForm.enter_id
      data.class_ids = this.searchForm.office_id
      data.page = 1;
      data.pagesize = 1000000;
      this.$http.post("/api/stu_allocate_list", data).then(res => {
        let header = ['序号','学号','姓名','实习去向','班级','医院名称','岗位','单位联系人','单位联系电话','分配时间','实习开始时间','实习结束时间']
        let exportData = []
        if (res.data && res.data.data.length) {
          res.data.data.forEach((item,index) => {
            exportData.push([
                ++index,
                item.stu_code,
                item.stu_name,
                item.param_desc,
                item.class_name,
                item.firm_name,
                item.job,
                item.mentor_content_name,
                item.mentor_content_tel,
                item.cdate,
                item.start_time,
                item.end_time,
            ])
          })

          export_json_to_excel(header, exportData, '实习记录导出')
        }
      });
    },
  }
};
</script>

<style scoped lang="less">
</style>